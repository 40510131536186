//jQuery( window ).resize(function() {
//	var fixed_area = jQuery('.fixed').outerHeight(true) ;
//	jQuery( ".fixed_start" ).height(fixed_area);
	
//});

//jQuery( document ).ready(function(){
//	jQuery('.fixed').addClass('affix');
//	var fixed_area = jQuery('.fixed').outerHeight(true) ;
//	jQuery('.fixed_start').height(fixed_area);
	
//});

function updateHeader(area) {
	let fixedArea = area.getBoundingClientRect().height
	document.documentElement.style.setProperty('--headerHeight', fixedArea + 'px')
	console.log('header', fixedArea)
}

{
	const fixed = document.querySelector('.fixed')
	fixed.classList.add('affix')
	updateHeader(fixed)

	let resizing

	window.addEventListener('resize', () => {
		clearTimeout(resizing)
		resizing = setTimeout( resize, 100)
	})	

	function resize() {
		updateHeader(fixed)
	}
}