class ScrollingText {
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.windowWidth = window.innerWidth
		this.desiredWidth = this.windowWidth * 2

		this.wordsP = this.container.querySelector('p')
		this.words = this.wordsP.innerHTML,
		this.width = this.wordsP.getBoundingClientRect().width

		this.container.style.setProperty('--animationDistance', '-' + this.width + 'px')

		this.init()
	}

	calculate() {
		let width = this.width,
		words = this.words
		while(width < this.windowWidth) {
			width += this.width
			words += this.words
		}
		words += this.words

		this.wordsP.innerHTML = words
	}

	init() {
		this.calculate()
	}
}

const scrolling_text = document.querySelectorAll('.notification_banner')
if(scrolling_text) {
	scrolling_text.forEach( (scroll) => {
		new ScrollingText(scroll)
	})
}
