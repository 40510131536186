jQuery(document).ready(function () {
	var acc = document.getElementsByClassName("mobile-dropdown");
	var i;
	for (i = 0; i < acc.length; i++) {
		acc[i].addEventListener("click", function() {
			this.classList.toggle("opened");
			var panel = this.nextElementSibling;
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
				panel.classList.toggle("opened");
			} else {
				panel.style.maxHeight = panel.scrollHeight + "px";
				panel.classList.toggle("opened");
			}
		});
	};

	if(window.innerWidth > 991 && jQuery('.event_sidebar').length) {
		eventsSidebar();
	}
});

jQuery( window ).resize(function() {
	if(window.innerWidth > 991 && jQuery('.event_sidebar').length) {
		eventsSidebar();
	} else {
		jQuery('.event_sidebar_container').css('height', 'auto');

		if(jQuery('.event_sidebar').data('bs.affix')) {
			jQuery('.event_sidebar').data('bs.affix').options.offset.top = 1000000000;
		}
	}
});

if( jQuery(".toggle .toggle_title").hasClass('active') ){
	jQuery(".toggle .toggle_title.active").closest('.toggle').find('.toggle_inner').show();
}

jQuery(".toggle .toggle_title").click(function() {
	if( jQuery(this).hasClass('active') ){
		jQuery(this).removeClass("active").closest('.toggle').find('.toggle_inner').slideUp(250);
	} else {
		jQuery(".toggle .toggle_title.active").removeClass("active").closest('.toggle').find('.toggle_inner').slideUp(250);

		jQuery(this).addClass("active").closest('.toggle').find('.toggle_inner').slideDown(250);
	}
});

jQuery(window).scroll(function() {
	if(jQuery('header.home').length) {
		navbarScroll();
	}
});

function navbarScroll() {
	if (jQuery("header").offset().top > 80) {
        jQuery("header").removeClass("transparent");
    } else {
        jQuery("header").addClass("transparent");
	}
}

jQuery('#monthSelect').on('change', function() {
	var url = this.value;

	window.location = url;
});

function getTop() {
	return(jQuery("header.header").outerHeight(true) + jQuery(".page_banner").outerHeight(true) + jQuery("#wpadminbar").outerHeight(true) - 150);
}

function eventsSidebar() {
	var sidebar_height = jQuery('.event_sidebar').outerHeight(true);
	jQuery('.event_sidebar_container').height(sidebar_height);

	jQuery(".event_sidebar").affix({
		offset: {
			top: getTop()
			,
			bottom: function() {
				return(jQuery(".extended_footer").outerHeight(true) + jQuery("footer.footer").outerHeight(true) + 50);
			}
		}
	});

	jQuery('.event_sidebar').data('bs.affix').options.offset.top = getTop();
}

jQuery(document).on('ready', function() {
	var notice_accept = getCookie("notice_accept");
	var verify_age = getCookie("verify_age");

	if(notice_accept == "" && verify_age == "") {
		setTimeout(function() {
			jQuery('.popup_notification').addClass('show');
		}, 1000);
	} else if(notice_accept == "" ) {
		jQuery('.popup_notification').addClass('show');
	}

	// jQuery('#accept-btn').on('click', function() {
	// 	setCookie("notice_accept", "accept", 1);
	// 	jQuery('.popup_notification').removeClass('show');
	// });

	jQuery('.close-btn').on('click', function() {
		setCookie("notice_accept", "accept", 0.041);
		jQuery('.popup_notification').removeClass('show');
	});
});

jQuery.fn.live = function(types, data, fn) {
	try {
	  if (
		typeof this.selector == "undefined" &&
		typeof this[0].id != "undefined"
	  ) {
		this.selector = String("#" + this[0].id);
	  }
	} catch (e) {}
  
	if (typeof this.context == "undefined") {
	  this.context = jQuery(this).parent();
	}
  
	jQuery(this.context).on(types, this.selector, data, fn);
	return this;
  };

document.addEventListener("DOMContentLoaded", function() {
	var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
	
	if ("IntersectionObserver" in window) {
		var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
							videoSource.src = videoSource.dataset.src;
						}
					}
					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});
		lazyVideos.forEach(function(lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
});