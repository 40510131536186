if(jQuery('.home_banner').length > 0) {
	jQuery('.home_banner').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		autoplay : false,
		autoplaySpeed: 4500,
		pauseOnHover: false,
		pauseOnFocus: false
	});
}

if(jQuery('.awards').length > 0) {
	jQuery('.awards').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		autoplay : true,
		autoplaySpeed: 1500,
		pauseOnHover: false,
		pauseOnFocus: false
	});
}

if(jQuery('.patrons_text').length > 0) {
	jQuery('.patrons_text').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
		autoplay : true,
		autoplaySpeed: 4000,
		pauseOnHover: false,
		pauseOnFocus: false
	});
}

if(jQuery('.slider_content').length > 0) {
	jQuery('.slider_content').each(function() {
		if(jQuery(this).children('.background').length > 1) {
			jQuery(this).slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: false,
				arrows: true,
				autoplay : true,
				autoplaySpeed: 1400,
				pauseOnHover: false,
				pauseOnFocus: false
			});
		}
	})
}

jQuery('.shop_banner').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	autoplay : true,
	autoplaySpeed: 2000,
	pauseOnHover: false,
	pauseOnFocus: false,
	draggable: true,
});