// Old age.js is moved to /parts

jQuery(document).ready(function() {
	var verify_age = getCookie("verify_age");

	if (verify_age == "") {
		jQuery('.age_verification').addClass('show');
	}

	jQuery('#confirmAge').on('click', function() {
		setCookie("verify_age", "accept", 1);

		jQuery('.age_verification .popup').addClass('zoomFade');

		setTimeout(function() {
			jQuery('.age_verification .popup').remove();
			jQuery('.age_verification .age_verification_bg').css({"filter": "blur(0)"});
		}, 1000);

		setTimeout(function() {
			jQuery('.age_verification').removeClass('show');

			setTimeout(function() {
				jQuery('.age_verification').remove();
			}, 1000);
		}, 2000);
	})
});

function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	var expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for(var i = 0; i <ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
