class ProductBanner{
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.windowWidth = window.innerWidth
		this.desiredWidth = this.windowWidth * 2

		this.wordsP = this.container.querySelector('.product_banner')
		this.cards = this.wordsP.querySelectorAll('.product_card')
		this.words = this.wordsP.innerHTML,
		this.width = this.wordsP.getBoundingClientRect().width

		this.container.style.setProperty('--animationDistance', '-' + this.width + 'px')

		this.init()
	}

	bindAll() {
		["pause", "resume"].forEach( (fn) => (this[fn] = this[fn].bind(this)))
	}

	pause(e) {
		console.log('pause')
		this.container.style.setProperty('--animationState', 'paused')
	}

	resume(e) {
		console.log('play')
		this.container.style.setProperty('--animationState', 'running')
	}

	randomAngle() {
		this.cards = this.wordsP.querySelectorAll('.product_card')
		this.cards.forEach( (el) => {
			let angle = Math.floor(Math.random() * (7)) - 4
			el.style.setProperty('--angle', angle + 'deg')
		})
	}

	calculate() {
		let width = this.width,
		words = this.words
		while(width < this.windowWidth) {
			width += this.width
			words += this.words
		}
		words += this.words
		this.wordsP.innerHTML = words

		this.randomAngle()
	}

	init() {
		this.calculate()

		window.addEventListener("resize", this.calculate)
	}
}

const product_banner = document.querySelectorAll('.product_banner_wrapper')
if(product_banner) {
	product_banner.forEach( (banner) => {
		new ProductBanner(banner)
	})
}
